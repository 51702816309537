/* Masthead.jsx styles */

.masthead > h2.text-uppercase {
  margin-bottom: 0;
}

.masthead .loginButtonContainer {
  margin-left: auto;
}

/* StatusPage.jsx styles */

.statusPageContainer {
  max-width: 600px;
  margin: auto;
  padding: 40px;
}

.statusPageContainer .iconContainer {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.statusPageContainer h1 {
  border: 0;
  text-align: center;
  display: block;
  padding-left: 0;
  margin-left: 0
}

.statusPageContainer p {
  text-align: center;
}