/* Loading.jsx styles */

.loadingContainer {
  width: 100%;
  height: 80vh;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.loadingContainer div {
  color: var(--red);
}