/* Global Styles */

.App {
  min-height: 100vh;
}

body {
  background-color: var(--paleCharcoal);
}

.aptLayout {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.bdoBlue {
  fill: #27348b;
}

.bdoRed {
  fill: #ed1a3b;
}

.bdoContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0;
  gap: 20px;
}

/* Header */

.masthead {
  padding: 0 var(--XXL);
  background: var(--paleCharcoalLight);
  box-shadow: 0 3px 6px 0px #3333331f;
  justify-self: flex-start;
  width: 100% !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.branding {
  align-items: center;
  height: var(--jumbo3);
  margin-bottom: -5px !important;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 7px;
}

.branding svg {
  height: var(--XXXL);
}

/* Body */

main {
  flex-grow: 1;
  padding: 0 var(--XXL);
}

.item {
  padding: var(--L);
  border-bottom: 1px solid var(--shade);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--L);
}

form {
  background: white !important;
  padding: var(--L);
  border: 1px solid #a1a1a178;
  max-width: 1200px;
  margin: 0 auto;
}

/* Footer */

footer {
  background: var(--paleCharcoalLight);
  box-shadow: 0 -3px 6px 0px #3333331f;
  width: 100% !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

footer div {
  height: var(--jumbo3);
  margin-top: auto;
  margin-bottom: -5px !important;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 20px;
  align-items: center;
  padding: 0 var(--XXL);
}

footer div svg {
  height: var(--XXXL);
  justify-self: flex-end;
}

/* Bootstrap button overrides */

.btn {
  --bs-btn-padding-x: 1rem;
  --bs-btn-padding-y: 0.875rem;
  --bs-btn-border-radius: 0px;
  --bs-btn-font-family: var(--fontHero);
  --bs-btn-line-height: 1rem;
  text-transform: uppercase;
}

.bdoPrimaryBtn {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ed1a3b;
  --bs-btn-border-color: #ed1a3b;
  --bs-btn-hover-color: #ed1a3b;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #ed1a3b;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ed1a3b;
  --bs-btn-active-border-color: #ed1a3b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #959597;
  --bs-btn-disabled-border-color: #959597;
}

.bdoSecondaryBtn {
  --bs-btn-color: #333333;
  --bs-btn-bg: #e5e5ea;
  --bs-btn-border-color: #e5e5ea;
  --bs-btn-hover-color: #333333;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #e5e5ea;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #333333;
  --bs-btn-active-bg: #e5e5ea;
  --bs-btn-active-border-color: #e5e5ea;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #959597;
  --bs-btn-disabled-border-color: #959597;
}

/* Bootstrap alert overrides */
.alert {
  --bs-alert-border-radius: 0;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.bdoAlertSuccess {
  --bs-alert-color: #fff;
  --bs-alert-bg: #54a575;
  --bs-alert-border-color: #54a575;
}

.bdoAlertError {
  --bs-alert-color: #fff;
  --bs-alert-bg: #98002e;
  --bs-alert-border-color: #98002e;
}

/* Bootstrap accordion overrides */

.accordion-button {
  background-color: #fff !important;
  color: #333333 !important;
}

.accordion-button::after {
	--bs-accordion-active-color: var(--red);
	--bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ed1a3b%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e")
}

.accordion-button:focus {
  box-shadow: none;
}
