/* AptEngagements.jsx styles */

div.submitButtonContainer {
  display: flex;
  margin-top: 20px;
}

div.submitButtonContainer .bdoPrimaryBtn {
  margin-left: auto !important;
}

/* EngagementsLayout.jsx */

.aptLayout h2 {
  text-transform: uppercase;
}

.aptLayout div {
  padding-bottom: 0.5rem;
}

















































































































































































































































































































































































